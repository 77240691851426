/* eslint-disable */
window.header = (function () {
  'use strict';

  const mainHeader = document.querySelector('header'),
    overlay = mainHeader.querySelector('.nav__overlay'),
    search = mainHeader.querySelector('.nav__search'),
    mobileMenu = mainHeader.querySelector('.mh--mobile-menu'),
    isWideScreen = window.matchMedia('(min-width: 940px)').matches,
    buttons = mainHeader.querySelectorAll('button.nav__subgroup, .mh--mobile-menu, .fld__item');
  let btnClose = mainHeader.querySelectorAll('.nav__btn-close'),
    login = mainHeader.querySelector('.nav__login.js-modal-trigger'),
    btnBack = mainHeader.querySelectorAll('.nav__btn-back');


  // TOGGLE ARIA-EXPANDED BETWEEN TRUE OR FALSE
  function toggleAria(element) {
    if (element.getAttribute('aria-expanded') === 'false') {
      element.setAttribute('aria-expanded', 'true');
    } else {
      element.setAttribute('aria-expanded', 'false');
    }
  }

  // TOGGLE CLASS TO SHOW OR HIDE FOLDOUTS
  function toggleShow(element) {
    element.classList.toggle('mhf--show');
  }

  // FOCUS TRAP
  function focusTrap(elem) {
    const focusableElements = elem.querySelectorAll('a[href], button:not(.hidden)'),
      firstElement = focusableElements[0],
      lastElement = focusableElements[focusableElements.length - 1];

    firstElement.focus();

    firstElement.addEventListener('keydown', event => {
      if (event.shiftKey && event.keyCode === 9) {
        event.preventDefault();
        lastElement.focus();
      }
    });

    lastElement.addEventListener('keydown', event => {
      if (event.keyCode === 9 && !event.shiftKey) {
        event.preventDefault();
        firstElement.focus();
      }
    });
  }

  // RESET ALL STATES EN CLOSE EVERYTHING
  function resetMenu() {
    const shownElements = mainHeader.querySelectorAll('.mhf--show'),
      ariaButtons = mainHeader.querySelectorAll('button:not(.nav__btn-close):not(.nav__btn-back)');

    shownElements.forEach(element => toggleShow(element));
    ariaButtons.forEach(button => button.setAttribute('aria-expanded', 'false'));
    mainHeader.classList.remove('mh--opened');
  }

  // OPEN MENU
  function openMenu(button) {
    const navPrimary = mainHeader.querySelector('nav'),
      btn = button;

    mainHeader.classList.add('mh--opened');
    toggleAria(btn);

    if (btn === mobileMenu) {
      toggleShow(navPrimary);
      focusTrap(navPrimary);
    } else {
      toggleShow(btn.nextElementSibling);
      focusTrap(btn.nextElementSibling);
    }
  }

  // SET BALLOON TIP LOGIN
  function setTip() {
    const cntrPos = document.querySelector('.nav__container').getBoundingClientRect().left,
      btnPos = document.querySelector('.nav__login').getBoundingClientRect().right,
      calcPos = window.innerWidth - (cntrPos - 20) - btnPos,
      adjustedCalc = calcPos - (window.innerWidth > 1140 ? 17 : 0);

    document.documentElement.style.setProperty('--tip-position', `${adjustedCalc}px`);
  }

  // NAVIGATION BUTTONS
  buttons.forEach(button => {
    button.addEventListener('click', function (event) {
      const isOpen = button.getAttribute('aria-expanded') === 'true';

      if (isWideScreen) {
        resetMenu();
      }
      if (!isOpen) {
        event.stopPropagation();
        event.preventDefault();
        openMenu(this);
      }
    });
  });

  // LOGIN
  if (login) {
    login.addEventListener('click', () => {
      login.classList.add('modal--open');
      toggleAria(login);
      setTip();
    });
  };

  // GO BACK
  btnBack.forEach(btn => {
    btn.addEventListener('click', () => {
      toggleShow(btn.parentElement);
      toggleAria(btn.parentElement.previousElementSibling);
      btn.parentElement.previousElementSibling.focus();
    });
  });

  // CLOSE MENU & RESET FOCUS
  btnClose.forEach(btn => {
    btn.addEventListener('click', () => {
      resetMenu();
      if (isWideScreen) {
        focus(btn.closest('.mh--foldout').previousSibling);
      } else {
        mobileMenu.focus();
      }
    });
  });

  // CLOSE BY CLICK OUTSIDE MENU
  overlay.addEventListener('click', () => {
    resetMenu();
  });

  // CLOSE BY CLICK ON SEARCH
  if (search) {
    search.addEventListener('click', () => {
      resetMenu();
    });
  }


  // CLOSE BY ESCAPE
  document.addEventListener('keyup', event => {
    if (event.code === 'Escape') {
      resetMenu();
    }
  });

  // SCROLL COLOR EVENT
  window.addEventListener('scroll', () => {
    let scrollPosition = window.scrollY;

    // list of page types which are excluded (class on body)
    const bodyClasses = ['shop-page-checkout', 'mijninterpolis-inloggen'],
      bodyClassesCheck = bodyClasses.some(className => document.body.classList.contains(className));

    if (!bodyClassesCheck) {
      mainHeader.classList.toggle('mh--light', scrollPosition >= 10);
    }
  });


  // SCROLL COLOR EVENT
  window.addEventListener('scroll', () => {
    let scrollPosition = window.scrollY;

    // list of page types which are excluded (class on body)
    const bodyClasses = ['shop-page-checkout', 'mijninterpolis-inloggen'],
      bodyClassesCheck = bodyClasses.some(className => document.body.classList.contains(className));

    if (!bodyClassesCheck) {
      mainHeader.classList.toggle('nav-light', scrollPosition >= 10);
    }
  });

})();