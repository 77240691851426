
/* Use this for non standard action items. 
**
** No need to use it for buttons or links as they already have default a11y handlers
** from the browser
**
*/
window.a11y = (function() {
  
  return {
    addAccesibleEventListeners: function(elmnt, cb) {
      elmnt.addEventListener('click', function() {
          cb(this)
      });
      elmnt.addEventListener('keydown', function(e) {
        if (e.key === 'Enter') {
          e.preventDefault();
          cb(this);
        }
      });
    }
  }
})();
